import React from "react";
import { DiscordLogo } from "../logo/DiscordLogo";

export const HelpContact: React.FC<{
  title?: string;
  bg?: "" | "bg-blueGray-100";
}> = ({ title = "Still stuck?", bg = "" }) => {
  return (
    <section className={`py-20 ${bg}`}>
      <div className="container px-4 mx-auto">
        <div className="max-w-2xl lg:max-w-3xl mx-auto">
          <div className="mb-12 text-center">
            <h2 className="text-4xl font-bold font-heading">{title}</h2>
            <p className="text-blueGray-500">We're here to help</p>
          </div>
          <div className="flex flex-wrap -mx-3 text-center">
            <div className="w-full sm:w-1/2 px-3 mb-12">
              {/* use text-color-purple fill-current ? */}
              <DiscordLogo className="mb-6 h-8 w-8 mx-auto" />
              <div className="leading-relaxed">
                <span className="text-md sm:text-lg text-blueGray-500">
                  Discord
                </span>
                <a
                  className="block text-sm sm:text-md font-semibold underline text-blueGray-600 hover:text-blueGray-700"
                  href="https://discord.gg/7Q2dp2eTr4"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Message the team on Discord
                </a>
              </div>
            </div>
            <div className="w-full sm:w-1/2 px-3 mb-12">
              <svg
                className="mb-6 h-8 w-8 mx-auto text-purple-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                ></path>
              </svg>
              <div className="leading-relaxed">
                <span className="text-md sm:text-lg text-blueGray-500">
                  E-mail
                </span>
                <p className="text-sm sm:text-md font-semibold text-blueGray-600">
                  Send us an email:{" "}
                  <a
                    className="block underline hover:text-blueGray-700"
                    href="mailto:contact@zorbiapp.com"
                  >
                    contact@zorbiapp.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
