import React from "react";
import Layout from "../components/common/layout/layout";
import { Navigation } from "../components/common/navigation/navigation";
import { LocalFilesHelpPage } from "../components/pages/LocalFilesHelpPage";
import { Footer } from "../components/sections/footer";

import SEO from "../components/common/layout/seo";

const ExtensionLocalFilesHelpPage = () => {
  return (
    <Layout>
      <SEO title="Use Zorbi with PDFs" />
      <Navigation />
      <LocalFilesHelpPage />
      <Footer />
    </Layout>
  );
};

export default ExtensionLocalFilesHelpPage;
