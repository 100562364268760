import React from "react";
import WorkingFromAirportImg from "../../images/metis-assets/illustrations/working-from-airport.png";
import { HelpContact } from "../common/contact/HelpContact";

export const LocalFilesHelpPage: React.FC = () => {
  return (
    <>
      <section className="py-20 bg-blueGray-100">
        <div className="container px-4 mx-auto max-w-4xl">
          <div className="w-full px-8">
            <div className="mb-12 pb-12 border-b">
              <h2 className="mb-4 text-3xl lg:text-4xl font-bold font-heading text-center">
                How to use Zorbi on PDFs / Other Local Files.
              </h2>
            </div>
          </div>
          <div className="w-full px-8">
            <ul className="space-y-12">
              <li className="flex -mx-4">
                <div className="px-4">
                  <span className="flex w-16 h-16 mx-auto items-center justify-center text-2xl font-bold font-heading rounded-full bg-purple-50 text-purple-600">
                    1
                  </span>
                </div>
                <div className="px-4">
                  <h3 className="my-4 text-xl font-semibold">
                    Find Zorbi in your extension settings
                  </h3>
                  <p className="text-blueGray-600">
                    {/* You may need to click 'Manage extensions'. */}
                  </p>
                </div>
              </li>
              <li className="flex -mx-4">
                <div className="px-4">
                  <span className="flex w-16 h-16 mx-auto items-center justify-center text-2xl font-bold font-heading rounded-full bg-purple-50 text-purple-600">
                    2
                  </span>
                </div>
                <div className="px-4">
                  <h3 className="my-4 text-xl font-semibold">
                    Enable 'Allow access to file URLs'
                  </h3>
                  <p className="text-blueGray-600 leading-loose">
                    This will let Zorbi open while you browse your files.
                  </p>
                </div>
              </li>
              <li className="flex -mx-4">
                <div className="px-4">
                  <span className="flex w-16 h-16 mx-auto items-center justify-center text-2xl font-bold font-heading rounded-full bg-purple-50 text-purple-600">
                    3
                  </span>
                </div>
                <div className="px-4">
                  <h3 className="my-4 text-xl font-semibold">That's it!</h3>
                  <p className="text-blueGray-600 leading-loose">
                    You're ready to go. Refresh your page and click the Zorbi
                    extension again.
                  </p>
                </div>
              </li>
            </ul>

            <div
              className="hidden md:block mt-24 relative h-0"
              style={{ paddingBottom: "56.25%" }}
            >
              <iframe
                frameBorder={0}
                allowFullScreen
                className="absolute left-0 top-0 w-full h-full"
                src="https://www.loom.com/embed/f2169439ebbb4b9294efc0d30613fecb?hide_owner=true&hide_speed=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
              />
            </div>
          </div>
        </div>
      </section>
      <HelpContact />
    </>
  );
};
